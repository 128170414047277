export const TraningProgramView = ({ ...props }) => {
	return (
		<div className="wrap traning_page">
			<div className="content_area">
				<div className="container tpContainer">
					<p className="topTitleText">투어캐디 아카데미</p>
					<p className="topSubTitleText">
						KPGA 및 KLPGA 투어전문 캐디 양성으로 수급 불균형 해소 및 대한민국 골프투어의
						질적 향상을 도모함과 더불어 세계로 뻗어나갈 수 있는 투어캐디 양성을 위한
						국내 유일의 아카데미 입니다.
					</p>

					<ul className="nav nav-tabs nav-tabs-camel" id="myTab" role="tablist">
						<li className="nav-item w-25" role="presentation">
							<a
								className="nav-link tab-camel active"
								id="tpTab001-tab"
								data-bs-toggle="tab"
								href="#tpTab001"
								role="tab"
								aria-controls="tpTab001"
								aria-selected="true">
								커리큘럼
							</a>
						</li>
						<li className="nav-item w-25" role="presentation">
							<a
								className="nav-link tab-camel"
								id="tpTab002-tab"
								data-bs-toggle="tab"
								href="#tpTab002"
								role="tab"
								aria-controls="tpTab002"
								aria-selected="false">
								강의일정
							</a>
						</li>
						<li className="nav-item w-25" role="presentation">
							<a
								className="nav-link tab-camel"
								id="tpTab003-tab"
								data-bs-toggle="tab"
								href="#tpTab003"
								role="tab"
								aria-controls="tpTab003"
								aria-selected="false">
								교육신청
							</a>
						</li>
						<li className="nav-item w-25" role="presentation">
							<a
								className="nav-link tab-camel"
								id="tpTab004-tab"
								data-bs-toggle="tab"
								href="#tpTab004"
								role="tab"
								aria-controls="tpTab004"
								aria-selected="false">
								문의사항
							</a>
						</li>
					</ul>

					<div className="tab-content" id="tpTabContent">
						<div
							className="tab-pane fade active show"
							id="tpTab001"
							role="tabpanel"
							aria-labelledby="tpTab001-tab">
							<p className="tabTitle">
								<span className="text-nowrap">국내 유일 /</span>{' '}
								<span className="text-nowrap">최고 수준 교육 커리큘럼</span>
							</p>
							<p className="tabContentText color-spruce text-center mt-3 mb-5rem">
								Education Curriculum
							</p>
							<div className="row curriculumBox">
								<div className="col-6 col-md-4">
									<img
										src={`/images/curriculum_001.png`}
										alt="img"
										className="curriculumImg"
									/>
								</div>
								<div className="col-6 col-md-4 text-center">
									<img
										src={`/images/curriculum_002.png`}
										alt="img"
										className="curriculumImg"
									/>
								</div>
								<div className="col-6 col-md-4 text-end">
									<img
										src={`/images/curriculum_003.png`}
										alt="img"
										className="curriculumImg"
									/>
								</div>
								<div className="col-6 col-md-4">
									<img
										src={`/images/curriculum_004.png`}
										alt="img"
										className="curriculumImg"
									/>
								</div>
								<div className="col-6 col-md-4 text-center">
									<img
										src={`/images/curriculum_005.png`}
										alt="img"
										className="curriculumImg"
									/>
								</div>
								<div className="col-6 col-md-4 text-end">
									<img
										src={`/images/curriculum_006.png`}
										alt="img"
										className="curriculumImg"
									/>
								</div>
							</div>
						</div>

						<div
							className="tab-pane fade"
							id="tpTab002"
							role="tabpanel"
							aria-labelledby="tpTab002-tab">
							<p className="tabTitle mb-5rem">강의 일정</p>

							<div className="tpTab002-content">
								<p className="tabContentText color-greyish-two text-left mb-3">
									모집 기간
								</p>
								<p className="tabContentText color-greyish-brown text-left mb-5rem">
									2024.12.01 - 충원시 마감
								</p>
								<p className="tabContentText color-greyish-two text-left mb-3">
									최종 선발인원
								</p>
								<p className="tabContentText color-greyish-brown text-left mb-5rem">
									20명
								</p>
								<p className="tabContentText color-greyish-two text-left mb-3">
									교육 일정
								</p>
								<p className="tabContentText color-greyish-brown text-left mb-5rem">
									2025년 2월 / 3주간 예정(추후공지)
								</p>
								<p className="tabContentText color-greyish-two text-left mb-3">
									모집대상
								</p>
								<p className="tabContentText color-greyish-brown text-left mb-3">
									<span className="font-M00">1. 연령: </span> 25세 이상
								</p>
								<p className="tabContentText color-greyish-brown text-left mb-3">
									<span className="font-M00">2. 학력: </span> 고등학교 졸업 이상
									또는 이와 동등한 학력 소지자
								</p>
								<p className="tabContentText color-greyish-brown text-left mb-3">
									<span className="font-M00">3. 필수조건: </span> 병역 필 또는 군
									면제자, 해외 여행에 결격 사유가 없는자
								</p>
								<p className="tabContentText color-greyish-brown text-left mb-3">
									<span className="font-M00">4. 우대조건: </span> 골프 관련 학과
									재학생 또는 졸업생, 골프관련 자격증 소지자,
									<br />
									하우스 캐디 경력자, 자차 보유자
								</p>

								<p className="tabContentText color-greyish-brown text-left mb-5">
									<span className="font-M00">
										상기 모집은 선착순으로 마감 되오니 빠른 신청 바랍니다.
									</span>
								</p>
								<p
									className="tabContentText text-left mb-5"
									style={{ color: '#d8d8d8' }}>
									* 교육일정 및 내용은 상황에 따라{' '}
									<span className="text-nowrap">변동 될 수 있습니다.</span>
								</p>
							</div>
						</div>

						<div
							className="tab-pane fade"
							id="tpTab003"
							role="tabpanel"
							aria-labelledby="tpTab003-tab">
							<div className="row justify-content-center align-items-center">
								<div className="col-12">
									<p className="tabTitle mb-5rem">교육 신청</p>
								</div>

								<div className="col-12 col-md-8">
									<img
										src={`/images/applyEducation.png`}
										alt="img"
										className="w-100 mb-5rem"
									/>
								</div>
							</div>

							<div className="row align-items-center">
								<div className="col-12 col-md-4  offset-md-2" offset-md-2>
									<a
										href="https://docs.google.com/forms/d/14YcwOztGULY0zUaFwYJa1QN1tzzoz6JuP5P52FFBoVw/edit"
										target="_blank"
										className="btn btn-lg btn-secondary btnDownload">
										참가 신청하기
									</a>
								</div>
								<div className="col-12 col-md-4">
									<a
										href="http://pf.kakao.com/_vAAxiK/chat"
										target="_blank"
										className="btn btn-lg btn-kakao btnDownload">
										<img
											src={`/images/kakaoIcon.png`}
											alt="img"
											className="kakaoIcon"
										/>{' '}
										카카오톡으로 상담하기
									</a>
								</div>
							</div>
						</div>

						<div
							className="tab-pane fade"
							id="tpTab004"
							role="tabpanel"
							aria-labelledby="tpTab004-tab">
							<p className="tabTitle mb-5rem">문의사항</p>
							<img src={`/images/wifi.png`} alt="img" className="mb-5rem mx-auto" />
							<p className="tabContentText color-greyish-brown text-center mb-5rem">
								<span className="text-nowrap">커리큘럼 및 교육에 대해</span>{' '}
								<span className="text-nowrap">문의사항이 있는 경우</span>{' '}
								<span className="text-nowrap">연락을 통해 친절하게</span>{' '}
								<span className="text-nowrap">안내를 도와드리고 있습니다.</span>
							</p>
							<p className="tabContentText color-greyish-two text-center mb-3">
								연락처
							</p>
							<p className="tabContentText color-greyish-brown text-center mb-5rem">
								031 - 791 - 0720
							</p>
							<p className="tabContentText color-greyish-two text-center mb-3">
								메일 문의
							</p>
							<p className="tabContentText color-greyish-brown text-center mb-5rem">
								master@spoeum.com
							</p>
							<a href="http://pf.kakao.com/_vAAxiK/chat">
								<button type="button" className="btn btn-lg btn-kakao btnDownload">
									<img
										src={`/images/kakaoIcon.png`}
										alt="img"
										className="kakaoIcon"
									/>{' '}
									카카오톡으로 상담하기
								</button>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
